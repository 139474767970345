import * as React from "react";
import { Button, Heading } from "../Shared";

const PageError: React.FC<{ title: string }> = ({ title }) => {
  return (
    <>
      <section className="d-flex flex-grow-1 bg-blue align-items-center">
        <div className="container d-flex flex-column flex-grow-1 align-items-center pt-5 pb-5">
          <div className="container-sm-fixed text-center">
            <Heading title={title} isBold={true} variant={2} />
            <div className="mb-5"></div>
            <Button
              name="Go Back"
              type="button"
              title="Go Back"
              onClick={() => {
                history.back();
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default PageError;
